<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit, errors }">
      <form class="login" @submit.prevent="handleSubmit(onSubmit)">
        <div class="ym-toptitle-box">
          <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <router-link tag="a" to="/records/service-technician" class="button xbtn is-medium is-transparent">
                  <icon name="x"></icon>
                </router-link>
                <h2 class="title" v-if="isNew">Nowy serwisant</h2>
                <h2 class="title" v-else>Edytuj serwisanta</h2>
              </div>
              <div class='buttons level-right'>
                <button class="button is-medium is-primary">
                  <icon name="check"></icon><span>Zapisz</span>
                </button>
              </div>
            </div>
          </fixed-header>
        </div>
        <div
          :class="errors['firstname'] && errors['firstname'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Imię</label>
              <span v-if="errors['firstname'] && errors['firstname'].length > 0"
                class="help is-danger">{{ errors['firstname'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="firstname">
                  <input name="firstname" v-model="user.firstname" class="input is-medium" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['lastname'] && errors['lastname'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Nazwisko</label>
              <span v-if="errors['lastname'] && errors['lastname'].length > 0"
                class="help is-danger">{{ errors['lastname'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="lastname">
                  <input name="lastname" v-model="user.lastname" class="input is-medium" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['email'] && errors['email'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Email</label>
              <span v-if="errors['email'] && errors['email'].length > 0"
                class="help is-danger">{{ errors['email'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="email">
                  <input name="email" v-model="user.email" class="input is-medium" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['phone'] && errors['phone'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Telefon</label>
              <span v-if="errors['phone'] && errors['phone'].length > 0"
                class="help is-danger">{{ errors['phone'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="phone">
                  <input name="phone" v-model="user.phone" class="input is-medium" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['firmname'] && errors['firmname'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Nazwa firmy</label>
              <span v-if="errors['firmname'] && errors['firmname'].length > 0"
                class="help is-danger">{{ errors['firmname'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="firmname">
                  <input name="firmname" v-model="user.firmname" class="input is-medium" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isNew"
          style="display:none"
          :class="errors['password'] && errors['password'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_company_short">Hasło</label>
              <span v-if="errors['password'] && errors['password'].length > 0"
                class="help is-danger">{{ errors['password'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="password">
                  <b-input type="password" name="password" v-model="user.password" size="is-medium" password-reveal></b-input>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['nip'] && errors['nip'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="f_nip_short">NIP</label>
              <span v-if="errors['nip'] && errors['nip'].length > 0"
                class="help is-danger">{{ errors['nip'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="nip">
                  <input name="nip" v-model="user.nip" class="input is-medium" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['address'] && errors['address'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="">Ulica</label>
              <span v-if="errors['address'] && errors['address'].length > 0"
                class="help is-danger">{{ errors['address'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="" name="address">
                  <input name="address" v-model="user.address" class="input is-medium" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['city'] && errors['city'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="">Miasto</label>
              <span v-if="errors['city'] && errors['city'].length > 0"
                class="help is-danger">{{ errors['city'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="" name="city">
                  <input name="city" v-model="user.city" class="input is-medium" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['postcode'] && errors['postcode'].length > 0 ? 'columns is-variable is-6 is-multiline v-field v-error' : 'columns is-variable is-6 is-multiline'">
          <div class="column ym-column is-4">
            <div class="ym-label">
              <label for="">Kod pocztowy</label>
              <span v-if="errors['postcode'] && errors['postcode'].length > 0"
                class="help is-danger">{{ errors['postcode'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-8">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="" name="postcode">
                  <input name="postcode" v-model="user.postcode" class="input is-medium" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
    
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import FixedHeader from 'vue-fixed-header'
  import {mapActions, mapGetters} from 'vuex'

  import {
    ValidationObserver,
    ValidationProvider
  } from 'vee-validate';

  export default {
    name: 'ServiceTechnicianForm',
    props: {
      id: {
        type: Number,
        default: 0
      }
    },
    components: {
      FixedHeader,
      ValidationObserver,
      ValidationProvider
    },
    data: function () {
      return {
        user: {
          function_role: 'technical'
        }
      }
    },
    computed: {
      ...mapGetters({
        selectATechnician: 'orders/selectATechnician'
      }),         
      isNew() {
        return this.id === 0 ? true : false
      }
    },
    methods: {
      ...mapActions({
        getUser: 'records/getUser',
        postUser: 'records/postUser',
        disabledSidebar: 'setDisabledSidebar'
      }),       
      onSubmit() {
        this.postUser({user: this.user, id: this.$route.params.id })
          .then(resp => {
            this.$router.push({name: 'ServiceTechnicianDetails', params: { id: resp.data.id }})
          })
          .catch((error) => {
            this.user = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          }) 

      }
    },
    mounted() {
      this.disabledSidebar(true)
      if(!this.isNew) {
        this.getUser({id: this.$route.params.id})
          .then(resp => this.user = resp)
          .catch((error) => {
            this.user = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      }
    },
    destroyed() {
      if(!this.selectATechnician) {
        this.disabledSidebar(false)
      }
    }
  }
</script>